.App {
  height: 100vh;
  text-align: left;
}

.container-post {
  padding:  2%;
  display: flex;
  gap: 20;
  height: 100%;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: calc(9 / 16 * 100%);
}

.iframe-container:before {
    padding-top: 0!important
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

a:link {
  text-decoration: none;
  color: #0000ff;
  border-bottom: 0px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

}

a:hover {
  color:  #fff;
  text-underline: none;
}

hr {
  border-top: none;
  border-bottom: 2px solid #0000ff;
  margin: 1% 0 1% 0;
}

.frWrapperViews {
  width: 1px;
  min-width: 100%;
  *width: 100%;
  height: 100%;
  position:relative;
  padding-top:177%;
}

.frWrapperViews:fullscreen {
  width: 100%;
  height: 100%;
  position:relative;
  padding: 0;
}

.frViews {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;

}


@media only screen 
    and (device-width: 375px) {
    .container-post {
      
    }
}