.App {
  height: 100vh;
  text-align: left;
}

.container {
  margin: 3%;
}

/*h1 {
  font-family: "EuclidFlexMedium", sans-serif;
  font-style: normal;
  font-size: 80px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0;
}*/

h1 {
  font-family: "EuclidFlexMedium", sans-serif;
  color: #0000ff;
  display: inline;
  font-size: 34px;
  font-weight: 400;
  line-height: 1.15em;
}

h1 a {
  color: #0000ff;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

h1 a:hover {
  border-bottom: none;
  color: #fff;
}

h2 {
  font-family: "EuclidFlexMedium", sans-serif;
  font-size: 34px;
  padding: 20px 0 20px 0;
  margin: 0;
  line-height: 1.3em;
}

h3 {
  font-family: "EuclidFlexMedium", sans-serif;
  font-size: 20px;
  padding: 20px 0 20px 0;
  margin: 0;
  line-height: 1.3em;
}

h4 {
  font-family: "Space Mono", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 16px;
}

.name {
  font-family: "EuclidFlexMedium", sans-serif;
}

.EuclidFlexMedium {
  font-family: "EuclidFlexMedium", sans-serif;
}

.SpaceMono {
  font-family: "Space Mono", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.p1 {
  font-family: "EuclidFlexRegular", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 1.15em;
  padding: 20px 0 20px 0;
  text-align: left;
}

.p2 {
  font-family: "EuclidFlexRegular", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.25em;
  margin-bottom: 24px;
  text-align: left;
}

.inline {
  display: inline;
}
.row {
  display: flex;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 50%;
  padding: 10px;
}

a {
  text-decoration: none;
  color: #0000ff;
  border-bottom: 0px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

}

a:hover {
  color:  #fff;
}

hr {
  border-top: none;
  border-bottom: 2px solid #0000ff;
  margin: 1% 0 1% 0;
}

ul {
  padding-top: 0px;  
}

ol, li {
  line-height: 1.4em;
}

li {
  padding-left: 1em;
  text-indent: -1em;
}

li:before {
  content: "—";
  padding-right: 5px;
}

img {
  display: block;
  margin: 0 auto;
  max-width:750px;
  max-height:1000px;
  width: 100%;
  height: auto;
  object-fit: cover;
  padding: 3%;
}

.maxHeight {
  max-height: 500px;
}


.frWrapper {
  width: 1px;
  min-width: 100%;
  *width: 100%;
  height: 100%;
  position:relative;
  padding-top:177%;
}

.frWrapper:fullscreen {
  width: 100%;
  height: 100%;
  position:relative;
  padding: 0;
}

.fr {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5%;
}

iframe {
  margin: 0;
  padding: 0;
}

.h_iframe {
  position: relative;
}
.h_iframe .ratio {
  display: block;
  width: 1px;
  min-width: 100%;
  *width: 100%;
  height: 100%;
  overflow:auto;
}
.h_iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  min-width: 100%;
  *width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 3%;
}

.mint-container {
  margin-top: 20px;
  color: #eda282;
}

.footer {

}

.footerLink {
    color: #eda282;
    border-bottom: 0px;
    text-decoration:none;
}

.footerLink a a:link a:visited {
    color: #eda282;
    border-bottom: 0px;
    text-decoration:none;
}

.footerLink a:hover {
    border-bottom: 10px bottom #eda282;
    color: #fff;
}


.wallet-connect {
  color: #eda282;
  font-family: 'Space Mono', monospace;
  font-size: 1em;
  padding-top: 5px;
  padding-bottom: 50px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .p1 {
    font-size: 28px; /* 34 */
  }

  .p2 {
    font-size: 20px; /* 20 */
  }
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 0 1% 0 1%;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    padding: 15px 0 22px 0;
  }

  h3 {
    padding: 10px 0 20px 0;
  }

  a:hover {
    border-bottom: 2px solid #0000ff;
  }

  .p1 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .p2 {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
  .container {
    max-width: 100%;
    padding: 0 1% 0 1%;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    padding: 15px 0 22px 0;
  }

  h3 {
    padding: 10px 0 20px 0;
  }

  a:hover {
    border-bottom: 2px solid #0000ff;
  }

  .p1 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .p2 {
    font-size: 16px;
    margin-bottom: 12px;
  }
}