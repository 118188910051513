.mint-container {
  margin-top: 20px;
  color: #eda282;
}

.cta-button {
  height: 55px;
  border: 0;
  padding: 0 15px 0 15px;
  margin: 0 2px 0 2px;
  border-radius: 0px;
  color: #fff;
  font-family: "EuclidFlexRegular", sans-serif;
  font-size: 20px;
  color: #000;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.connect-wallet-button {
  background: #fff;
  cursor: pointer;
}

.connect-wallet-button:hover {
  background: #0000ff;
}

.button-select {
  width: auto;
  background: #fff;
  gap: 12;
}

.button-select:hover {
  background: #0000ff;
}

.button-select:disabled {
  background: #8f8f8f;
  cursor: default
}

.wrong-error-button {
  width: auto;
  background: #fff;
  color: #b30000;
  cursor: pointer;
}

.wrong-error-button:hover {
  background: #0000ff;
}

.mint-not-active {
  background: #8f8f8f;
  cursor: default
}

.mint-not-active:disabled {
  background: #8f8f8f;
  cursor: default
}

.mint-button {
  background: #fff;
  cursor: pointer;
  width: 200px;
}

.mint-button:disabled {
  background: #8f8f8f;
  text-decoration: none;
  cursor: default
}

.mint-button:disabled:hover {
  background: #8f8f8f;
  color: black;
}


.minting {
  width: 200px;
  background-image: -webkit-linear-gradient(0deg, #0000ff 0%, #fff 50%, #0000ff 75%);
  background-size: 200% auto;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  color: #fff;
  animation: gradient-animation 6s ease 0s infinite;
}

option {
  color: #000;
  background: #fff;
  height: 55px;
}

option:hover {
  background: #0000ff;
}



.label {
  font-family: "Space Mono", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 16px;
  line-height: 1.15em;
}

.errorMaxLength {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100ch;
}

.messages {
  padding: 30px 0 0 0;
}

.message-error {
  color: #b30000;
  font-family: "Space Mono", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 16px;
}

.mint-button {
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.mint-button:hover {
  background: #0000ff;
  color: #fff;
}

.no-mint-button {
  background: #000;
  color: #fff;
  margin-right: 15px;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.mint-count {
  color: #0000ff;
  font-family: "EuclidFlexRegular", sans-serif;
  font-size: 1em;
  padding-top: 10px;
  padding-bottom: 0px;
}

/* KeyFrames */

@keyframes gradient-animation {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
